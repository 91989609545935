import {ModalBase} from "../modal_base";

export class TcoMisMilCommentAddBulkNonePaginated extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_add_bulk_none_paginated');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        console.log($modalTriggerBtn, additionalData);
        app.init_tco_mis_mil_autocomplete(this.MODAL_CONTAINER_EL);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment_bulk').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment_bulk').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment_bulk').removeClass('error');
            const el = $(e.currentTarget);
            let data = additionalData;
            if(el.data('btn-action')) {
                data.modalClickedAction = el.data('btn-action');
            }

            const event = new CustomEvent('ModalConfirm', {
                detail: data
            });
            event.modalId = this.MODAL_ID;
            event.modalTriggerButton = $modalTriggerBtn;
            window.dispatchEvent(event);
        });
    }
}